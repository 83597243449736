(function () {
    "use strict"

    /**
     * Variables
     */
    const body = document.querySelector('body');
    const head = document.querySelector('head');

    /**
     * Main nav toggle
     */
    if (document.getElementById('nav-menu-toggle')) {
        const navToggle = document.getElementById('nav-menu-toggle')
        const mainNav = document.getElementById('nav')
        const page = document.querySelector('html, body')

        navToggle.addEventListener('click', e => {
            navToggle.classList.toggle('--active')
            mainNav.classList.toggle('--active')
            page.classList.toggle('--locked');
        })
    }

    /**
     * Calculate and return the absolute height of the DOM element
     */
    function absoluteHeight(elem) {
        const height = elem.offsetHeight,
              style = window.getComputedStyle(elem)

        return ['top', 'bottom']
            .map(side => parseInt(style[`margin-${side}`]))
            .reduce((total, side) => total + side, height)
    }

    /**
     * Sticky navigation
     */
    if (document.getElementById('sticky')) {
        const header = document.getElementById('header')
        const sticky = document.getElementById('sticky')
        const logo = document.getElementById('logo')
        const navToggle = document.getElementById('nav-menu-toggle')

        window.addEventListener('scroll', e => {
            sticky.classList.toggle('sticky', window.scrollY > absoluteHeight(header))
            navToggle.classList.toggle('--rev', window.scrollY > absoluteHeight(header))
            logo.setAttribute('src', window.scrollY > absoluteHeight(header) ? logo.dataset.alt : logo.dataset.src)
        })
    }

    /**
     * Splide carousel
     */
    if (document.querySelector('.splide')) {

        // Create slide-code.min.css resource
        var css = document.createElement('link')
            css.rel = 'stylesheet'
            css.href = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.0.7/dist/css/splide-core.min.css'
            css.media = 'all'

        // Append resource to the head
        head.append(css)

        // Create splide.min.css resource
        var css = document.createElement('link')
            css.rel = 'stylesheet'
            css.href = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.0.7/dist/css/splide.min.css'
            css.media = 'all'

        // Append resource to the head
        head.append(css)

        // Create slide.min.js resource
        var lib = document.createElement('script')
            lib.src = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.0.7/dist/js/splide.min.js'

        // Append resource to after the body
        body.append(lib)

        // When main library is loaded, initialise splide
        lib.onload = function () {
            new Splide('.splide', {
                type: 'loop',
                perPage: 5,
                speed: 350,
                // fixedWidth: '300px',
                // fixedHeight: '375px',
                // gap: '1.5rem',
                autoplay: true,
                interval: 3000,
                perMove: 1,
                pagination: false,
                lazyLoad: 'sequential',
                arrows: false,
                easing: 'linear',
                breakpoints: {
                    1120: {
                        perPage: 4,
                    },
                    1024: {
                        perPage: 3,
                    },
                    680: {
                        perPage: 2,
                    },
                    478: {
                        perPage: 1,
                    }
                }
            }).mount()
        }
    }

    /**
     * Animate on scroll initializer
     */
    if (document.querySelector('[data-aos]')) {
        var lib = document.createElement('script')
            lib.src = 'https://unpkg.com/aos@2.3.1/dist/aos.js'

        body.append(lib)

        lib.onload = function () {
            AOS.init({
                offset: 120,
                once: true,
                duration: 600,
                easing: 'ease'
            })
        }
    }

    /**
     * WordCloud loader and initializer
     */
    if (document.getElementById('WordCloud')) {

        // Create script tag for the first library
        let lib1 = document.createElement('script')
            lib1.src = 'https://cdn.anychart.com/releases/8.11.0/js/anychart-core.min.js'
            lib1.defer = true

        // Append the first library
        body.append(lib1)

        // When first library is loaded
        lib1.onload = function () {

            // Create script tag for the second library
            let lib2 = document.createElement('script')
                lib2.src = 'https://cdn.anychart.com/releases/8.11.0/js/anychart-tag-cloud.min.js'

            // Append the second library
            body.append(lib2)

            // Invoke createWordCloud() function when second library is loaded
            lib2.onload = createWordCloud()
        }

        /**
         * CreateWordCloud
         * @return void
         */
        function createWordCloud() {
            anychart.onDocumentReady(() => {

                // Parse the data
                const data = JSON.parse(WordCloudData)

                // Create custom color scale
                const customColorScale = anychart.scales.linearColor()
                customColorScale.colors(['#9d302a', '#344657'])

                // Create a tag (word) cloud chart
                const chart = anychart.tagCloud(data)

                // Chart settings
                chart.colorScale(customColorScale)
                chart.angles([0])
                chart.tooltip(false)
                chart.colorRange().enabled(false)
                chart.colorRange().length('90%')
                chart.background().enabled(false)
                chart.fontFamily('azo-sans-web')
                chart.scale(anychart.scales.log())
                chart.fontWeight(400)
                chart.mode('spiral')

                // Display a WordCloud chart
                chart.container('WordCloud')
                chart.draw()

                document.querySelector('.anychart-credits').style.display = 'none'
            })
        }
    }

    /**
     * Typewriter effect
     * @credit Simon Shahriveri
     * @url https://codepen.io/hi-im-si/pen/ALgzqo
     */
    if (document.querySelector('[data-type]')) {

        var TxtType = function(el, toRotate, period) {
            this.toRotate = toRotate
            this.el = el
            this.loopNum = 0
            this.period = parseInt(period, 10) || 2000
            this.txt = ''
            this.tick()
            this.isDeleting = false
        };
    
        TxtType.prototype.tick = function () {
            let i = this.loopNum % this.toRotate.length
            let fullTxt = this.toRotate[i]
    
            if (this.isDeleting) {
                this.txt = fullTxt.substring(0, this.txt.length - 1)
            }
            else {
                this.txt = fullTxt.substring(0, this.txt.length + 1)
            }
    
            this.el.innerHTML = `<span>${this.txt}</span>`
    
            let that = this
            let delta = 200 - Math.random() * 100
    
            if (this.isDeleting) {
                delta /= 2
            }
    
            if (!this.isDeleting && this.txt === fullTxt) {
                delta = this.period
                this.isDeleting = true
            } 
            else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false
                this.loopNum++
                delta = 500
            }
    
            setTimeout(function () {
                that.tick();
            }, delta)
        };
    
        window.onload = function () {
            const elements = document.getElementsByClassName('typewrite')
            for (let i=0; i<elements.length; i++) {
                let toRotate = elements[i].getAttribute('data-type')
                let period = elements[i].getAttribute('data-period')

                if (toRotate) {
                  new TxtType(elements[i], JSON.parse(toRotate), period)
                }
            }

            // Inject CSS
            const css = document.createElement('style')
            css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff; }"
            document.head.appendChild(css)
        };
    }

    if (document.querySelectorAll('.parallax-container')) {
        var lib = document.createElement('script');
            lib.src = 'https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js';

        body.append(lib);

        lib.onload = function () {
            new simpleParallax(document.querySelectorAll('.parallax-container img'), {
                customWrapper: '.parallax-container',
                scale: 1.4
            });
        }
    }

})()